<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('vipRecommend.recommendReason')"/>
        <div class="main">
            <img alt="" class="show-image" src="@/assets/images/antu.png">
            <div class="project-name-key">{{ $t('vipRecommend.projectName') }}</div>
            <div class="project-name-value">Reevo：无轮毂电动自行车</div>
            <div class="reason">
                <!-- 翻译：推荐原因-->
                <h1>{{ $t('vipRecommend.reasonsForRecommendation') }}</h1>
                <p class="over4">
                    平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因</p>
            </div>
            <div class="content">
                <div class="title">内容</div>
                <div class="tip">旨在跨越故事部分宽度的图像的最小宽度应为695像素。宽于695像素的图像将按比例调整大小。</div>
            </div>
            <RichText/>
        </div>

    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import RichText from "@/components/richText";

    export default {
        name: "RecommendReason",
        data() {
            return {};
        },
        components: {
            BreadcrumbNav,
            RichText,
        },
        props: {},
    }
</script>

<style scoped>
    .container {
        background-color: #FFFFFF;
    }

    .main {
        width: 1200px;
        margin: 0 auto;
        padding: 71px 0 53px;
    }

    .show-image {
        width: 695px;
        height: 460px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
    }

    .project-name-key {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        margin-top: 47px;
    }

    .project-name-value {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 46px;
        color: #000000;
        margin-top: 14px;
    }

    .reason {
        margin-top: 53px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .content {
        margin-top: 32px;
    }

    .content > .title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .content > .tip {
        margin-top: 14px;
        margin-bottom: 18px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
    }
</style>
